$border-left-size: 8px;
$border-radius: 5px;

#interview {
  .pm,
  .shorten,
  .question {
    padding-left: $border-left-size + 5px;
    margin: 0;
    overflow: hidden;
    padding: 0.7em 1em;

    background-size: 25px auto;
    background-position-y: 50%;
    background-position-x: 98%;
    background-repeat: no-repeat;

    p {
      margin: 0;
    }
  }

  .question {
    margin-top: 1.3em;
    color: getCol("dark");
    background-color: rgba(getCol("grey-dark"), 0.15);
    border-radius: $border-radius $border-radius 0 0;
    border-left: $border-left-size solid getCol("dark");
    border-bottom: 1px solid getCol("dark");
  }

  .pm {
    border-left: $border-left-size solid getCol("liberal");
    border-bottom: 1px solid getCol("liberal");
    background-color: getCol("light");
    background-image: url("../img/logo_lib.png");
    padding-right: 40px;
  }

  .shorten {
    border-left: $border-left-size solid getCol("labor");
    border-bottom: 1px solid getCol("labor");
    background-color: getCol("light");
    background-image: url("../img/logo_labor.png");
    padding-right: 40px;
  }
}

#interview #question {
  margin-top: 1.8em;
  position: relative;

  background: rgba(getCol("dark"), 0.1);
  padding: 2em 2em 2em 1.5em;
  border-radius: $border-radius;
  margin-bottom: 3em;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(6, auto);
  grid-template-areas:
    "heading"
    "suggestions-header"
    "suggestions"
    "diy-header"
    "diy"
    "submit";

  grid-row-gap: 1em;
  grid-column-gap: 1em;

  #heading {
    grid-area: heading;
    color: getCol("heading");

    text-transform: uppercase;
    text-align: center;
    font-size: 2.2em;
    padding-bottom: 0.5em;
    margin: 0;
  }

  #suggestions-header {
    grid-area: suggestions-header;
  }
  #diy-header {
    grid-area: diy-header;
  }
  #diy {
    grid-area: diy;
  }
  #suggestions {
    grid-area: suggestions;
  }

  #suggestions-header,
  #diy-header,
  #diy,
  #suggestions,
  #submit {
    margin: 0;
  }

  h4 {
    font-size: 1.2em;
    font-weight: bold;
    padding: 0;
  }

  textarea {
    width: 100%;
    height: 100%;
    border: 1px solid getCol("grey-mid");
    border-radius: $border-radius $border-radius $border-radius $border-radius;
    background-color: getCol("light");
  }

  #suggestions {
    margin-bottom: 0.5em;
    border-radius: $border-radius $border-radius $border-radius $border-radius;

    @include md-screen {
      font-size: 0.9em;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      display: block;
      margin: 0;

      button {
        color: getCol("dark");
        display: block;
        width: 100%;
        text-align: left;

        padding: 0.8em 1em 1em 1em;
        line-height: 1.4em;
        border: none;
        border-bottom: 1px dashed getCol("grey-mid");
        cursor: pointer;
        background-color: rgba(getCol("light"), 0.8);
        outline: none;
      }

      &:first-child {
        button {
          border-radius: $border-radius $border-radius 0 0;
        }
      }

      &:last-child {
        button {
          border-bottom: none;
          border-radius: 0 0 $border-radius $border-radius;
        }
      }
    }
  }

  #submit {
    border-radius: 8px;
    padding: 0.6em 1em;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid getCol("highlight");
    transition: all 0.5s;

    justify-self: center;
    grid-area: submit;

    &:hover {
      box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5);
      transition: all 0.2s;
    }

    &:disabled {
      box-shadow: none;
      border-color: rgba(getCol("dark"), 0.3);
      color: rgba(getCol("dark"), 0.3);
      background-color: rgba(getCol("dark"), 0.08);
    }
  }

  @include md-screen {
    grid-template-rows: repeat(5, auto);
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "heading heading"
      "suggestions-header diy-header"
      "suggestions diy"
      "submit submit";

    h4 {
      font-size: 1em;
      font-weight: 400;
      text-align: center;
    }

    #submit {
      margin-top: 1em;
    }
  }
}

#interview {
  #spinner {
    display: none;

    position: relative;
    width: 100%;
    height: 100%;
    grid-column: 1/-1;
    grid-row: 1/-1;
    transition: all 150ms;

    svg {
      position: absolute;
      display: block;
      left: calc(50% - 1.5em);
      top: calc(50% - 1.5em);
    }
  }

  &.loading {
    #question {
      opacity: 0.5;
    }
    #spinner {
      display: block;
    }
  }
}
