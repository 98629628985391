@import url("https://fonts.googleapis.com/css?family=Open+Sans:400|Cabin:500");
$bodyfont: "Open Sans", sans-serif;
$titlefont: "Lora", serif;
$headingfont: "Open Sans", sans-serif;

body {
  font-family: $bodyfont;
  font-weight: 300;

  color: getCol("body");
  font-size: $base-fontsize * 0.85;

  @include md-screen {
    font-size: $base-fontsize * 0.9;
  }
  @include lg-screen {
    font-size: $base-fontsize;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headingfont;
  color: getCol("heading");
  font-weight: 400;
  font-style: normal;
}

h2 {
  font-family: $titlefont;
  text-transform: uppercase;
  font-size: 1.05em;
  font-weight: 700;
}

p,
label,
li,
textarea {
  display: block;
  font-size: 1em;
  line-height: 1.421em;
  margin: 0px 0px 1.421em 0px;
  font-family: $bodyfont;
}

a {
  color: inherit;
}

.invert {
  p,
  li,
  blockquote {
    color: getCol("light");
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: getCol("light");
  }
}

blockquote {
  font-family: $headingfont;
  font-size: 1.7em;
  line-height: 1.6em;
  font-style: italic;
  margin-top: 1.5em;
  margin-bottom: 2em;
}
