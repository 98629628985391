html {
  font-size: $one-rem;
  margin: 0;
  padding: 0;
  background: getCol("dark");
}

body {
  margin: 0;
  padding: 0;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  z-index: 2;
  padding-bottom: 4em;
}

body > header,
main,
main .fullbleed,
body > footer {
  display: grid;
  grid-template-columns: minmax(3em, auto) minmax(28rem, 65rem) minmax(3em, auto);
  @include lg-screen {
    grid-template-columns: minmax(2em, auto) minmax(28rem, 70rem) minmax(2em, auto);
  }

  > *:not(.fullbleed) {
    grid-column: 2;
  }

  > .fullbleed {
    grid-column: 1/-1;
  }

  .padded {
    margin-bottom: 2em;
  }
}
