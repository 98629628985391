#pageHeader {
  // position: relative;
  // text-align: center;

  grid-template-rows: 6em auto;
  grid-template-areas:
    ". pagetitle ."
    ". subtitle .";

  margin-bottom: 2em;

  > * {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &:first-child {
    padding-top: 6em;
  }

  h1 {
    grid-area: pagetitle;
    text-indent: -9999px;

    background-image: url("../img/goc-logo-black.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }

  h3 {
    grid-area: subtitle;

    padding-top: 1vw;

    color: getCol("accent");
    font-family: $titlefont;
    text-align: center;
    font-size: 0.6em;
    line-height: 2.2em;

    span {
      display: block;
      font-size: 1.8em;
    }

    text-transform: uppercase;
  }
}

@include sm-screen {
  #pageHeader {
    grid-template-rows: 8em auto;
    margin-bottom: 3.5em;

    h3 {
      font-size: 0.8em;
    }
  }
}

@include lg-screen {
  #pageHeader {
    grid-template-rows: 8em auto;
    margin-bottom: 4.5em;

    h3 {
      font-size: 0.8em;
      span {
        display: block;
        font-size: 2em;
      }
    }
  }
}
