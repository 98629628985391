body {
  background: url("../img/bg-texture.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  background-color: rgba(getCol("grey-light"), 0.5);
  background-blend-mode: overlay;

  position: relative;

  &::before,
  &::after {
    content: ".";
    position: absolute;
    bottom: 4.6rem;
    z-index: 1;
    width: 180px;
    height: 220px;

    transition: all 500ms;

    @include sm-screen {
      width: 220px;
      height: 280px;
    }
    @include md-screen {
      width: 280px;
      height: 350px;
    }

    background-repeat: no-repeat;
    background-size: contain;
  }

  &::before {
    opacity: 0.2;
    background-image: url("../img/shorten-trans.png");

    background-position: bottom left;
    background-position: bottom -50px;
  }
  &::after {
    opacity: 0.2;
    background-image: url("../img/morrison-trans.png");
    background-position: bottom right;
    right: 0;
  }
}

.intro {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  padding: 3em 0 4em;
  // grid-row-gap: 1em;

  background-image: url("../img/Insitu_1.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-blend-mode: overlay;

  margin-bottom: 3em;
  background-color: rgba(getCol("dark"), 0.7);
  border-top: 1px dashed (rgba(getCol("dark"), 0.9));
  border-bottom: 1px dashed (rgba(getCol("dark"), 0.9));
}

.banner {
  font-size: 1.2em;
  padding: 1em 0 1.5em;
  color: getCol("light");
  @include sm-screen {
    padding: 1em 1em 1em 1.5em;
  }
}

.tiled {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 2.5em;

  @include sm-screen {
    grid-template-columns: 1fr 1fr;
  }
}

.tiled > section {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-row-gap: 1.3em;

  color: getCol("light");

  h3 {
    font-size: 1.2em;
    text-transform: uppercase;
    padding-bottom: 5px;
    border-bottom: 1px solid getCol("grey-light");
    color: inherit;
  }

  .cta {
    width: 80%;

    a {
      display: block;
      color: getCol("light");

      border-radius: 4px;
      padding-top: 1em;
      padding-bottom: 1em;

      border: 1px solid darken(getCol("highlight"), 20);

      font-size: 0.9em;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;

      box-shadow: 3px 3px 4px rgba(getCol("dark"), 0.4);

      background-color: rgba(getCol("highlight"), 1);
      transition: all 100ms ease-out;

      &:hover {
        box-shadow: 1px 1px 2px rgba(getCol("dark"), 0.7);
        background-color: darken(getCol("highlight"), 20);
        transform: scale(0.97);
      }
    }
  }

  @include sm-screen {
    font-size: 0.9em;
    border-radius: 5px;
    padding: 1.5em 2.5em;
    text-align: center;

    background-color: rgba(getCol("light"), 0.7);
    color: getCol("dark");

    .cta {
      margin: 0 auto;
      width: 95%;
    }

    h3 {
      border-bottom: 1px solid getCol("grey-dark");
    }
  }

  :first-child {
    padding-top: 0;
    margin-top: 0;
  }

  :last-child {
    padding-bottom: 1em;
    margin-bottom: 0;
  }
}

#posters {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 2em;
  margin-bottom: 3em;
  @include sm-screen {
    grid-template-columns: 1fr 1fr;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    border: 2px solid getCol("dark");
  }

  .wide {
    grid-column: 1/-1;
  }
}
