@mixin portrait-screen {
  @media only screen and (orientation: portrait) {
    @content;
  }
}

@mixin landscape-screen {
  @media only screen and (orientation: landscape) {
    @content;
  }
}

@mixin xs-screen {
  @media (max-width: $sm - 1) {
    @content;
  }
}
@mixin xs-screen {
  @media (max-width: $sm - 1) {
    @content;
  }
}

@mixin sm-screen {
  @media (min-width: $sm) {
    @content;
  }
}

@mixin md-screen {
  @media (min-width: $md) {
    @content;
  }
}

@mixin lg-screen {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin xl-screen {
  @media (min-width: $xl) {
    @content;
  }
}
