#siteNav {
  #nav-button {
    box-shadow: 1px 1px 8px rgba(getCol("dark"), 0.4);
  }

  &.open {
    #nav-button {
      box-shadow: 2px 2px 2px rgba(getCol("dark"), 0.25);
      transform: scale(0.95);
      bottom: -2.55em;
    }
    box-shadow: 3px 3px 12px rgba(getCol("dark"), 0.4);
  }
}

#nav-button {
  position: absolute;
  transition: all 150ms;

  z-index: 50;
  width: 100px;
  height: 2.6em;
  left: 10px;
  bottom: -2.6em;
  border: none;
  outline: none;
  cursor: pointer;

  a {
    display: block;
    text-decoration: none;
    text-align: center;
    padding-top: 0.5em;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
  }

  border-radius: 0 0 5px 5px;
  background-color: getCol("accent");
  color: getCol("light");
}

#siteNav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 1em;

  transform: translate3d(0, -100%, 0);

  color: getCol("light");
  background: getCol("accent");

  transition: transform 400ms ease-in-out;

  &.open {
    transform: translate3d(0, 0, 0);
  }

  h2 {
    font-size: 1.2em;
    font-family: $headingfont;
    color: getCol("light");
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(getCol("light"), 0.4);
  }

  ul {
    margin: 0 0 2em 0;
    padding: 0;

    li {
      display: block;
      margin: 0 0 1em 0;

      a {
        text-decoration: none;
        transition: all 150ms;
      }

      a:hover {
        padding-left: 5px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
