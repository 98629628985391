// assign a specific map of colors to a global $palette var
$palette: $palette-default;
$cols: (
  primary: map-get($palette, "primary"),
  success: map-get($palette, "success"),
  accent: map-get($palette, "accent"),
  highlight: map-get($palette, "highlight"),
  light: map-get($palette, "light"),
  dark: map-get($palette, "dark"),
  grey-light: map-get($palette, "grey-light"),
  grey-dark: map-get($palette, "grey-dark"),
  grey-mid: map-get($palette, "grey-mid"),
  body: rgba(map-get($palette, "dark"), 0.9),
  heading: rgba(map-get($palette, "dark"), 0.85),
  liberal: #00529c,
  liberal-tint: rgba(56, 182, 255, 0.58),
  labor: #bf2232,
  labor-tint: rgba(255, 168, 168, 0.78)
);

$sm: 500px;
$md: 900px;
$lg: 1200px;
$xl: 1800px;

$one-rem: 10px;
$base-fontsize: 1.5rem;

$bubble-bg-opacity: 0.7;
